import {
  ArrowHelper,
  AxesHelper,
  GridHelper,
  Matrix4,
  Scene,
  Vector3,
  WebGLRenderer
} from 'three'
import { PerspectiveCamera } from './perspective-camera.js'
import { getRotationMatrix } from './util.js'
import './index.css'

const { PI, floor } = Math
const degtorad = PI / 180

const canvas = document.querySelector('#canvas')
const compass = document.querySelector('#compass')
const debug = document.querySelector('#debug')

const renderer = new WebGLRenderer({ canvas, alpha: true })

const camera = new PerspectiveCamera(60, 9/16)
camera.position.set(0, 5, 10)

const scene = new Scene()
scene.matrixAutoUpdate = false
const grid = new GridHelper(10, 10, "aqua", "gray")
scene.add(grid)
const arrow = new ArrowHelper(new Vector3(0, 0, -1),undefined,5)
scene.add(arrow)
const axes = new AxesHelper(6)
scene.add(axes)

let orientati0n = { alpha: 0, beta: 0, gamma: 0 }
let webkitAlphaOffset

let androidDebug = '-'
let iOSDebug = '-'

const rotationMatrix = new Matrix4()
const screenTransform = new Matrix4()
const worldTransform = new Matrix4().makeRotationX(90 * degtorad)
const gl = renderer.getContext()
const webGLVersion = gl.getParameter(gl.VERSION);
const webGLSLVersion = gl.getParameter(gl.SHADING_LANGUAGE_VERSION)

let permission = 'denied'
if ('DeviceOrientationEvent' in window === false) {
  permission = 'not supported'
} else if ('requestPermission' in DeviceOrientationEvent) {
  permission = 'tap to request'
  async function requestPermission() {
    permission = await DeviceOrientationEvent.requestPermission()
  }
  window.addEventListener('click', requestPermission, { once: true })
  window.addEventListener('touchend', requestPermission, { once: true })
}


globalThis.showAndroidToast = function(toast) {
  try{
    Android.showToast(toast);
    console.log("yes. android.");    
    androidDebug = "yes. android.   => " + toast;
  }catch(e){
    console.log("no. android. :(");
    androidDebug = "no. android.   => " + toast;
  }
}

globalThis.showIOSToast = function(src)
{
    var rootElm = document.documentElement;
    var newFrameElm = document.createElement("IFRAME");
    newFrameElm.setAttribute("src",src);
    rootElm.appendChild(newFrameElm);
    newFrameElm.parentNode.removeChild(newFrameElm);
    console.log("showIOSToast:", src);
    iOSDebug = "iOS    => " + src;
}

// webview.loadUrl("javascript:showAndroidToast(\"Toast to a toast/'')");

/*document.getElementById('buttonAndroid').addEventListener('click', function(e) {
  console.log('buttonAndroid: Click happened for: ' + e.target.id);
  console.log(e);
  showAndroidToast('Cheese Toasty please');
});

document.getElementById('buttonIOS').addEventListener('click', function(e) {
  console.log('buttonIOS: Click happened for: ' + e.target.id);
  showIOSToast('ios-cheddar-cheese');
});

document.getElementById('buttonTemp').addEventListener('click', function(e) {
  console.log('buttonIOS: Click happened for: ' + e.target.id);
  setDeviceOrientation(100,100,100);
});*/


renderer.setAnimationLoop(() => {

  const { orientation = 0 } = window
  const { alpha, beta, gamma } = orientati0n

  // Build matrix with DOM rotation space
  rotationMatrix.elements = getRotationMatrix(-alpha, -beta, gamma)

  // Transform to DOM screen space
  screenTransform.makeRotationZ(-orientation * degtorad)
  rotationMatrix.premultiply(screenTransform)

  compass.style.transform = `matrix3d(${rotationMatrix.elements})`

  // Rebuild matrix with Three rotation space
  rotationMatrix.elements = getRotationMatrix(alpha, beta, gamma)

  // Transform to Three screen space
  screenTransform.makeRotationZ(orientation * degtorad)
  rotationMatrix.premultiply(screenTransform)

  // Transform to Three world space
  rotationMatrix.multiply(worldTransform)

  scene.matrix.copy(rotationMatrix)
  scene.matrixWorldNeedsUpdate = true

  const { clientHeight, clientWidth } = canvas
  camera.cover(clientWidth, clientHeight)
  if (camera.needsUpdate) {
    camera.updateProjectionMatrix()
  }

  renderer.setSize(clientWidth, clientHeight, false)
  renderer.render(scene, camera)

  debug.innerHTML = `${webGLVersion}
<br>${webGLSLVersion}
<br>Screen: ${clientWidth}x${clientHeight}, ${orientation}°
<br>DeviceOrientationEvent: ${permission}
<br>𝝰: ${floor(alpha)}°
<br>𝝱: ${floor(beta)}°
<br>𝝲: ${floor(gamma)}°
<br>
<br> android: ${androidDebug}
<br> ios: ${iOSDebug}
`
})


globalThis.setDeviceOrientation = function(alpha,beta,gamma){
  let webkitCompassAccuracy = 0;
  let webkitCompassHeading = 0;

  if (webkitCompassAccuracy > 0 && !webkitAlphaOffset) {
    webkitAlphaOffset = -webkitCompassHeading
  }
  if (webkitAlphaOffset) {
    alpha += webkitAlphaOffset
  }
  orientati0n = { alpha, beta, gamma } 
}



const orientationHandler = ('ondeviceorientationabsolute' in window)
  ? 'deviceorientationabsolute'
  : 'deviceorientation'
window.addEventListener(orientationHandler, event => {
  permission = 'granted'

  let {
    alpha,
    beta,
    gamma,
    webkitCompassAccuracy = 0,
    webkitCompassHeading = 0
  } = event

  if (webkitCompassAccuracy > 0 && !webkitAlphaOffset) {
    webkitAlphaOffset = -webkitCompassHeading
  }

  if (webkitAlphaOffset) {
    alpha += webkitAlphaOffset
  }

  orientati0n = { alpha, beta, gamma }

})
